import { Box, styled, Typography } from '@mui/material';
import { Link } from 'components';
import {
  emptyOr,
  mustBeAuNumber,
  mustBeAge,
  mustBePostcode,
  mustBeDateOfBirth,
  mustBeYearOfBirth,
  mustBeValidDays,
  mustBeTrue,
} from 'components/form';
import { QuestionItem, QuestionSection } from 'app/survey/questions';
import update from 'immutability-helper';

const Article = styled('article')(({ theme }) => ({
  '& li': {
    ...theme.typography.body1,
  },
}));

export enum UserCategory {
  Participant = 0, //parent, guardian
  Other, //professional, researcher or other accessing the PiP Kids-Autism program
}

export const getProfileQuestions = (opts: { userCategory?: UserCategory }): QuestionSection[] => {
  const { userCategory } = opts;
  let questions: QuestionSection[] = [
    {
      key: 'welcome',
      title: 'Partners in Parenting',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography variant="subtitle1" gutterBottom>
                The <i>Partners in Parenting Kids-Autism</i> program aims to guide parents to support their child’s
                mental wellbeing by providing personalised feedback about current parenting practices and equipping
                parents with evidence based parenting strategies. These strategies aim to help to reduce or prevent
                depression and anxiety disorders in autistic children.
              </Typography>
            </Article>
          ),
        },
      ],
    },
    {
      key: 'userType',
      title: 'Eligibility check',
      questions: [
        {
          key: 'userCategory',
          type: 'select',
          label: 'Are you a parent/guardian taking part in the PiP Kids-Autism program?',
          options: [
            { label: 'Yes, I am a participant of PiP Kids-Autism', value: 'participant' },
            {
              label: 'No, I am a professional, researcher or other accessing the PiP Kids-Autism program',
              value: 'other',
            },
          ],
          required: true,
        },
      ],
    },
    {
      key: 'consent',
      title: 'Informed Consent Form: Parent/Guardian',
      questions: [
        {
          key: 'consentForm',
          type: 'paragraph',
          content: (
            <Article>
              <Typography variant="subtitle1" gutterBottom>
                Project ID: 40824
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Project Title: Feasibility and acceptability pilot trial of a coach-supported, online parenting
                intervention for parents of autistic children to reduce internalising problems
              </Typography>
              <Typography variant="body1" paragraph>
                I have been asked to take part in the Monash University research project specified above. I confirm that
                I have read and understood the{' '}
                <a href="/pdfs/PKA_0006_Explanatory%20Statement_Parent%20(v3.1).pdf" target="_blank">
                Explanatory Statement
                </a>{' '}
                and I understand and agree that:
              </Typography>
              <ul>
                <li>
                  My participation in this research is voluntary and that my decision to participate or not, will in no
                  way impact upon my or my child’s relationship with Monash University or its staff;
                </li>
                <li>
                  I can withdraw consent and discontinue participation in this research at any time without explanation.
                  I am also able to withdraw any unprocessed identifiable data, up until the point that the final report
                  is published;
                </li>
                <li>
                  I will complete the PiP Kids-Autism online program designed for parents of Autistic children who are
                  experiencing symptoms of anxiety and/or depression;
                </li>
                <li>
                  I will take part in the one-on-one parent coaching sessions with my dedicated PiP Kids-Autism coach
                  (between the hours of 9am - 4pm on weekdays);
                </li>
                <li>
                  I may be asked to participate in a post-intervention group interview (approx. 1hr) after completing
                  the online program, to share my views on my engagement with the program and the acceptability of the
                  program;
                </li>
                <li>
                  Parent coaching sessions and focus groups (if applicable) will be audio and video recorded for coding
                  purposes;
                </li>
                <li>
                  I will complete online surveys before and after completing the program about myself, my parenting and
                  my child's development and behaviour;
                </li>
                <li>
                  Non-identifiable data (i.e., no one will ever be able to link my name back to my responses) gathered
                  from the program, surveys (and interview, if applicable) may be published in a scientific journal, PhD
                  thesis or conference presentation and may be made available for ethically approved future research via
                  a secure online repository.
                </li>
              </ul>
            </Article>
          ),
        },
        {
          key: 'childName',
          type: 'text',
          controlled: true,
          label: 'Child First Name',
          helperText: 'This is required to personalise the program to you and your child.',
          size: 'medium',
          required: true,
        },
        {
          key: 'childSurname',
          type: 'text',
          controlled: true,
          label: 'Child Surname',
          helperText: 'This is required to personalise the program to you and your child.',
          size: 'medium',
          required: true,
        },
        {
          key: 'childDob',
          type: 'text',
          controlled: true,
          label: 'Child DOB',
          size: 'standard',
          required: true,
          helperText: 'In dd/mm/yyyy format',
          validate: mustBeDateOfBirth('Must be a valid birth date in dd/mm/yyyy format before the current date.'),
        },
        {
          key: 'childGender',
          type: 'select',
          label: 'How do you describe your child’s gender?',
          options: [
            {
              label: 'Girl/Female',
              value: 'female',
            },
            {
              label: 'Boy/Male',
              value: 'male',
            },
            {
              label: 'Non-binary/Gender diverse/Gender-fluid',
              value: 'non-binary',
            },
            { label: 'Gender identity not listed here (please specify)', value: '_input' },
          ],
          // required: true,
          size: 'standard',
        },
        {
          key: 'childPronouns',
          type: 'select',
          label: 'What pronouns does your child use? ',
          helperText: 'The questions in the first survey, as well as the associated feedback, will be personalised to your child’s name and pronoun based on the 3 options currently available here in our system. Please indicate which pronouns you would like us to use for your child above. We apologise in advance that the remaining surveys refer only to the pronouns he/she and his/hers in some questions. We acknowledge these above limitations and are working to expand the language used to be more inclusive in the future.',
          options: [
            {
              label: 'him/his',
              value: 'male',
            },
            {
              label: 'her/hers',
              value: 'female',
            },
            {
              label: 'they/their',
              value: 'non-binary',
            }
          ],
          required: true,
          size: 'standard',
        },
        {
          key: 'userFirstname',
          type: 'text',
          controlled: true,
          label: 'Parent/Guardian First Name',
          required: true,
          size: 'small',
        },
        {
          key: 'userSurname',
          type: 'text',
          controlled: true,
          label: 'Parent/Guardian Surname',
          required: true,
          size: 'small',
        },
        // {
        //   key: 'userPhone',
        //   type: 'text',
        //   controlled: true,
        //   label: 'Mobile number',
        //   required: true,
        //   validate: mustBeAuNumber(
        //     'Must be an Australia phone number in the preferred format of +61403123456 or +61398764321. Please do not include any spaces.',
        //   ),
        //   size: 'small',
        // },
        {
          key: 'userContactAgreement',
          type: 'checkbox',
          label: 'I agree to being contacted by researchers about future research projects I may be interested in.',
          size: 'standard',
          disabled: false,
        },
        {
          key: 'userConsent',
          type: 'checkbox',
          label:
            'I have read and understood the information in the Explanatory Statement attached above and agree to participate in the PiP Kids-Autism project.',
          // required: true,
          validate: mustBeTrue('You must agree to the above statement to take part in this program.'),
          size: 'standard',
          disabled: false,
        },
      ],
    },
    {
      key: 'aboutUser',
      title: 'The following questions concern information about you, the person completing this questionnaire:',
      questions: [
        {
          key: 'userYob',
          type: 'text',
          controlled: true,
          label: 'Your year of birth',
          size: 'standard',
          required: true,
          helperText: 'In yyyy format',
          validate: mustBeYearOfBirth('Must be a valid birth date in yyyy format before the current date.'),
        },
        {
          key: 'userGender',
          type: 'select',
          title: 'How do you describe your gender?',
          inputLabel: 'Please enter your gender identity',
          label: 'Your gender',
          options: [
            { label: 'Woman/Female', value: 'female' },
            { label: 'Man/Male', value: 'male' },
            { label: 'Non-binary/Gender diverse/Gender-fluid', value: 'non-binary' },
            { label: 'Not listed here. I identify as ...(please specify)', value: '_input' },
            'Prefer not to say',
          ],
          // required: true,
        },
        {
          key: 'userPostcode',
          type: 'text',
          controlled: true,
          label: 'Postcode',
          title: 'What is your postcode',
          // required: true,
          size: 'medium',
          validate: mustBePostcode('Must be a valid postcode with 4 digits.'),
        },
        {
          key: 'childRelationship',
          type: 'select',
          title: 'What is your relationship to the child?',
          label: 'Your relationship to them',
          inputLabel: 'Please specify',
          options: [
            'Mother',
            'Father',
            'Grandparent',
            'Step parent',
            'Foster/Adoptive parent or Legal Guardian',
            { label: 'Other', value: '_input' },
          ],
          size: 'medium',
          required: true,
        },
        {
          key: 'familyEthnicity',
          type: 'checkgroup',
          size: 'standard',
          label: 'Ethnicity group',
          title: 'Which ethnicity do you most identify with? (Select as many as needed).',
          required: false,
          inputLabel: 'Please specify',
          options: [
            'North African',
            'Sub-Saharan African',
            'North American',
            'Central American',
            'South American',
            'East Asian e.g. Chinese, Japanese, Korean',
            'South Asian e.g. Indian, Sri Lankan',
            'Southeast Asian e.g. Vietnamese, Malaysian',
            'Australian Aboriginal or Torres Strait Islander',
            'Australian/ New Zealand',
            'European - Eastern',
            'European - Western',
            'Maori',
            'Middle Eastern e.g. Turkish, Syrian',
            'Pacific Islander',
            {
              label: 'Other',
              value: '_input-other',
            },
            'Prefer not to say',
          ],
        },
        {
          key: 'familyRelationship',
          type: 'select',
          size: 'medium',
          title: 'What is your relationship status?',
          label: 'Relationship status',
          options: [
            'Married',
            'Widowed',
            'Divorced/Separated',
            'In a de facto relationship',
            'In a relationship but not living with partner',
            'Not in a relationship',
            'Prefer not to say',
          ],
          required: true,
        },
        {
          key: 'userEducation',
          type: 'select',
          size: 'medium',
          title: 'What is your highest level of education?',
          label: 'Education',
          inputLabel: 'Please specify',
          options: [
            'Partial high school/secondary school',
            'Completed high school/secondary school',
            'Trade/apprenticeship',
            'Partial University/TAFE/Technical qualification (at least one year)',
            'Completed TAFE or technical qualification (inc. Cert I to IV)',
            'Completed diploma, advanced diploma or associate degree',
            'Completed Bachelor degree',
            'Completed Postgraduate degree (i.e. masters, doctorate/PhD)',
            { label: 'Other', value: '_input' },
            'Prefer not to say',
          ],
          required: true,
        },
        {
          key: 'userWorkStatus',
          type: 'checkgroup',
          size: 'medium',
          title: 'What is your current work status?',
          label: 'Work status',
          inputLabel: 'Please specify',
          options: [
            'Employed full-time (30+ hours/week) ',
            'Employed part-time',
            'Employed casually',
            'Employed, on extended leave (e.g. parental leave, long service leave)',
            'Full time student',
            'Part time student',
            'Self employed',
            'Unemployed and looking for work',
            'Full time parent/carer',
            'Not in paid employment',
          ],
          // required: true,
        },
        {
          key: 'userNeurodivergent',
          type: 'select',
          size: 'standard',
          title: 'Do you consider yourself neurodivergent (e.g., Autistic, ADHD, Dyslexia)?',
          inputLabel: 'Please provide further information',
          options: [{ label: 'Yes', value: '_input' }, 'No', 'Prefer not to say'],
          // required: true,
        },
      ],
    },
    {
      key: 'aboutFamily',
      title: 'The following questions concern information about your household:',
      questions: [
        {
          key: 'familyLanguage',
          type: 'checkgroup',
          size: 'medium',
          title: 'What is the main language spoken in your household? (select all that apply)*',
          label: 'Please specify',
          inputLabel: 'Please specify',
          options: ['English', { label: 'Other', value: '_input' }],
          required: true,
        },
      ],
    },
    {
      key: 'aboutChild',
      title: 'The following questions concern information about your child:',
      questions: [
        {
          key: 'childLivingWith',
          type: 'select',
          size: 'standard',
          title: 'Which of the following best describes who your child currently lives with?',
          label: 'Who does your child currently live with?',
          inputLabel: 'Please specify',
          options: [
            'Lives with both parents in the same home',
            'Lives with one parent primarily or full-time',
            'Lives with both parents in separate households on a regular basis',
            'Lives with at least one adult caregiver who is not a parent (e.g. grandparents, other relatives, legal guardian)',
            { label: 'Other (please specify)', value: '_input' },
          ],
          required: true,
        },
        {
          key: 'childFirstLanguageEnglish',
          type: 'select',
          label: 'Is English your child’s first language?',
          options: ['Yes', 'No'],
          size: 'standard',
          // required: true,
        },
        {
          key: 'childEnglishProficiency',
          type: 'select',
          title: 'If No, how well does your child speak English?',
          label: 'English language proficiency',
          options: ['Not Well', 'Well', 'Very Well'],
          cond: 'childFirstLanguageEnglish == No',
          // required: true,
          size: 'standard',
        },
        {
          key: 'childOtherLanguage',
          type: 'select',
          title: 'Does your child speak any languages other than English?',
          label: 'Other Languages',
          inputLabel: 'What other languages does your child speak?',
          options: [
            {
              label: 'Yes',
              value: '_input',
            },
            'No',
          ],
          size: 'standard',
          // required: true,
        },
        {
          key: 'childActivity',
          type: 'checkgroup',
          title: 'What are your child’s daytime activities now? (select all that apply)*',
          required: true,
          inputLabel: 'Please specify',
          options: [
            'Has not yet commenced formal primary education',
            'In a mainstream school without aide support',
            'In a mainstream school with aide support',
            'In a specialist school',
            'Home schooled',
            'Distance education',
            {
              label: 'Other',
              value: '_input-other',
            },
          ],
        },
        {
          key: 'childsSignificantLifeEvents',
          type: 'select',
          title:
            'Has your child experienced any significant life events in the past 12 months? (E.g. change in place of residence, serious illness, death of a close relative or friend, parents’ divorce)',
          inputLabel: 'Please specify',
          options: ['No', { label: 'Yes', value: '_input' }],
          // required: true,
        },
        {
          key: 'childDiagnosis',
          type: 'checkgroup',
          title: 'Has your child been diagnosed with any of the following? (select all that apply)*',
          label: 'Please tick all that apply ',
          options: [
            'Autism Spectrum Disorder (Autism, Asperger’s Syndrome, PDD-NOS)',
            'Attention Deficit Hyperactivity Disorder (ADHD)',
            'Blind / vision impairment',
            'Cerebral Palsy',
            'Deaf / hearing impairment',
            'Down Syndrome',
            'Epilepsy',
            'Foetal Alcohol Syndrome',
            'Fragile X Syndrome',
            'Intellectual developmental disorder (Intellectual disability)',
            'Language delay / disorder',
            'Motor Neuron Disease',
            'Multiple Sclerosis',
            'Muscular Dystrophy',
            'Para/quadri/hemiplegia',
            'Prader Willi Syndrome',
            'Spina Bifida',
            'Tuberous Sclerosis',
            'Velo Cardio Facial Syndrome (22q11.2 deletion syndrome)',
            'Williams Syndrome',
            { label: 'Other chromosome or genetic disorder, please describe:', value: '_input-other-chromosome' },
            { label: 'Other disorder, please describe:', value: '_input-other-disorder' },
            'None of the above',
          ],
          required: true,
          size: 'standard',
        },
        {
          key: 'childMedicalCondition',
          type: 'select',
          title:
            'Does your child have any medical conditions that have an impact on their daily life (e.g. asthma, epilepsy, diabetes)*?',
          inputLabel: 'Please specify',
          options: ['No', { label: 'Yes', value: '_input' }],
          required: true,
        },
      ],
    },
    {
      key: 'notifications',
      title: 'Manage notifications',
      questions: [
        {
          key: 'intro',
          type: 'paragraph',
          content: (
            <Article>
              <Typography paragraph>Choose the notifications you'd like to receive from PiP Kids-Autism.</Typography>
              <Typography paragraph color="textSecondary">
                You will receive email alerts when you have new modules or surveys due to be completed. If you would
                like to receive SMS notifications, in addition to email, please check the box below. You can update this
                at any time under the 'settings' on your dashboard.
              </Typography>
            </Article>
          ),
        },
        {
          key: 'emailNotification',
          type: 'checkbox',
          label: 'Receive Email Notifications',
          disabled: true,
        },
        {
          key: 'smsNotification',
          type: 'checkbox',
          label: 'Receive SMS Notifications',
          disabled: false,
        },
      ],
    },
  ];

  // extra text for eligible check
  if (userCategory === UserCategory.Other) {
    questions = update(questions, {
      1: {
        questions: {
          $push: [
            {
              key: 'userCategoryEligible',
              type: 'paragraph',
              content: (
                <Typography variant="subtitle2" gutterBottom>
                  Thanks for letting us know. You're welcome to check out the online program, your experience of the
                  program will appear the same as it does for parents. Your data will not be included in the associated
                  research project. Please feel free to contact us if you have any questions about the program.
                </Typography>
              ),
            } as QuestionItem,
          ],
        },
      },
    });
  }

  return questions;
};
